import { render, staticRenderFns } from "./CardSliderEducation.vue?vue&type=template&id=6b47e31a&"
import script from "./CardSliderEducation.vue?vue&type=script&lang=ts&"
export * from "./CardSliderEducation.vue?vue&type=script&lang=ts&"
import style0 from "./CardSliderEducation.vue?vue&type=style&index=0&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseSlider: require('/var/www/src/components/BaseSlider/BaseSlider.vue').default,BaseButton: require('/var/www/src/components/BaseButton/BaseButton.vue').default,MicroLoader: require('/var/www/src/components/MicroLoader/MicroLoader.vue').default})
