import { computed, defineComponent, onMounted, ref, useCssModule, watch } from '@nuxtjs/composition-api';
import { DEFAULT_OPTIONS } from './';
export default defineComponent({
    name: 'CardSliderEducation',
    props: {
        data: {
            default: () => [],
            type: Array,
        },
        options: {
            default: () => DEFAULT_OPTIONS,
            type: Object,
        },
        size: {
            required: true,
            type: Object,
        },
    },
    setup(props) {
        /**
         * CSS Composables.
         */
        const css = useCssModule();
        const rootEl = ref();
        const sliderOptions = computed(() => {
            return {
                ...DEFAULT_OPTIONS,
                ...props.options,
            };
        });
        const slidesComponents = {
            article: () => import('~/components/CardArticle/CardArticle.vue'),
            news: () => import('~/components/CardNews/CardNews.vue'),
            opinion: () => import('~/components/CardOpinion/CardOpinion.vue'),
            podcast: () => import('~/components/EducationSlidePodcast/EducationSlidePodcast.vue'),
        };
        const slidesOptions = {
            article: {
                withImage: true,
            },
        };
        const slides = computed(() => {
            // Alternation of podcasts from different series one after another
            if (sliderOptions.value.type === 'podcast') {
                let serialsUrls = new Set();
                for (const { data } of props.data) {
                    const url = data?.serial?.url || '';
                    serialsUrls.add(url);
                }
                if (serialsUrls.size === 1) {
                    return props.data;
                }
                serialsUrls = Array.from(serialsUrls);
                const slides = [];
                const slidesRef = props.data.map((item) => item);
                while (slidesRef.length) {
                    let [firstUrl] = serialsUrls || [];
                    const i = slidesRef.findIndex(({ data = {} }) => data?.serial?.url === firstUrl);
                    if (i > -1) {
                        slides.push(slidesRef[i]);
                        slidesRef.splice(i, 1);
                    }
                    firstUrl = serialsUrls.shift();
                    serialsUrls.push(firstUrl);
                }
                return slides;
            }
            // Handle slides with multiple rows
            if (sliderOptions.value.itemRows && sliderOptions.value.itemRows > 1) {
                const numberOfSlides = Math.ceil(props.data.length / sliderOptions.value.itemRows);
                const slides = [];
                let itemIndex = 0;
                let slideIndex = 0;
                for (let i = 0; i < numberOfSlides; i++) {
                    for (let j = 0; j < sliderOptions.value.itemRows; j++) {
                        itemIndex = i + j + slideIndex;
                        if (itemIndex >= props.data.length) {
                            break;
                        }
                        slides[i] = slides[i] || [];
                        slides[i].push(props.data[itemIndex]);
                    }
                    slideIndex++;
                }
                return slides;
            }
            // Return unchanged data
            return props.data;
        });
        onMounted(() => {
            const unwatch = watch(slides, async () => {
                if (slides.value.length && rootEl.value?.$el) {
                    await new Promise((resolve) => setTimeout(resolve, 500));
                    rootEl.value.$el.style.minHeight = 'unset';
                    unwatch();
                }
            });
        });
        return {
            css,
            rootEl,
            slides,
            sliderOptions,
            slidesComponents,
            slidesOptions,
        };
    },
});
