var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items.length)?_c('div',{class:( _obj = {}, _obj[_vm.css.root] = true, _obj[_vm.css.isButtonsHoverable] = _vm.isButtonsHoverable, _obj[_vm.css.withTitle] = _vm.title, _obj[_vm.css.withRows] = _vm.itemRows > 1, _obj[_vm.css.withButtons] = _vm.withButtons, _obj[_vm.css.withItemsShadows] = _vm.withItemsShadows, _obj[_vm.css.withBorder] = _vm.withSliderBorder, _obj[_vm.css[("buttons-align-" + _vm.buttonsAlign)]] = true, _obj[_vm.css[("buttons-justify-" + _vm.buttonsJustify)]] = true, _obj ),style:({
    '--button-size': _vm.ICON_BUTTON_SIZE_MAP[_vm.buttonsSize],
    '--edge-gap': _vm.currentEdgeGap,
    '--item-gap': _vm.currentGap,
    '--item-width': _vm.currentWidth,
  })},[(_vm.title)?_c('p',{class:( _obj$1 = {}, _obj$1[_vm.css.title] = true, _obj$1[_vm.css[_vm.titleSize]] = true, _obj$1 )},[_vm._v("\n    "+_vm._s(_vm.title)+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.subtitle)?_c('p',{class:_vm.css.subtitle,domProps:{"innerHTML":_vm._s(_vm.subtitle)}}):_vm._e(),_vm._v(" "),_c('div',{ref:"sliderEl",class:_vm.css.slider},[_c('ul',{ref:"itemsEl",class:_vm.css.items},_vm._l((_vm.items),function(item,itemKey){return _c('li',{key:itemKey,class:_vm.css.item,style:({
          scrollSnapAlign: _vm.scrollSnapAlign,
          scrollSnapStop: _vm.scrollSnapStop,
        }),attrs:{"data-index":itemKey,"data-slide":itemKey + 1}},[_vm._t("default",null,{"index":itemKey,"item":item})],2)}),0)]),_vm._v(" "),(_vm.$viewport.isLessThan('tablet') && _vm.withDots && _vm.items.length > 1)?_c('ul',{class:_vm.css.dots},_vm._l((_vm.items.length),function(dotIndex){
        var _obj;
return _c('li',{key:dotIndex,class:( _obj = {}, _obj[_vm.css.dot] = true, _obj[_vm.css.dotActive] = dotIndex - 1 === _vm.currentIndex, _obj )})}),0):_vm._e(),_vm._v(" "),(_vm.withButtons)?[_c('IconButton',{class:_vm.css.buttonBackward,attrs:{"disabled":!_vm.canScrollBackward,"is-disabled":!_vm.canScrollBackward,"name":"chevron-2","size":_vm.buttonsSize,"theme":"white"},nativeOn:{"click":function($event){return _vm.scrollBackward.apply(null, arguments)}}}),_vm._v(" "),_c('IconButton',{class:_vm.css.buttonForward,attrs:{"disabled":!_vm.canScrollForward,"is-disabled":!_vm.canScrollForward,"name":"chevron-2","size":_vm.buttonsSize,"theme":"white"},nativeOn:{"click":function($event){return _vm.scrollForward.apply(null, arguments)}}})]:_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }