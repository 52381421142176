export const DEFAULT_OPTIONS = {
    buttonsAlign: 'start',
    buttonsJustify: 'end',
    buttonsSize: 'default',
    itemGap: '16px',
    itemRows: 1,
    itemWidth: '300px',
    length: 12,
    scrollSnapAlign: 'start',
    type: 'news',
    withDots: false,
    withItemsShadows: false,
};
