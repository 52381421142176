// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zBTd1{position:relative;padding:24px 0 32px;background-color:#fff}@media screen and (min-width:768px){.zBTd1{padding:0;background:0}}.zBTd1.gGZZ-{padding-bottom:16px}.zBTd1.yw83W{min-height:236px}@media screen and (min-width:768px){.zBTd1.yw83W{min-height:184px}}.zBTd1.yw83W._97yLm{min-height:370px}@media screen and (min-width:768px){.zBTd1.yw83W._97yLm{min-height:326px}}.zBTd1.SXbNQ{min-height:452px}@media screen and (min-width:768px){.zBTd1.SXbNQ{min-height:430px}}.zBTd1.yPU5s{min-height:384px}@media screen and (min-width:768px){.zBTd1.yPU5s{min-height:430px}}.zBTd1.iikwm{min-height:482px}@media screen and (min-width:768px){.zBTd1.iikwm{min-height:358px}}.TojBu{margin:0 0 24px;font-family:\"Merriweather\",serif;font-size:28px;font-weight:700;line-height:34px}.zBTd1 .tGRQ5{margin-left:0;margin-right:0}@media screen and (min-width:768px){.zBTd1.SXbNQ .tGRQ5,.zBTd1.yPU5s .tGRQ5{height:292px}}.d8hsz{padding:0 8px;margin-top:24px}@media screen and (min-width:425px){.d8hsz{padding:0 16px}}@media screen and (min-width:768px){.d8hsz{padding:0;margin-top:32px;text-align:center}}.gGZZ- .d8hsz{margin-top:8px;margin-bottom:16px}.qvUOc{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "zBTd1",
	"withItemsShadows": "gGZZ-",
	"news": "yw83W",
	"rows-2": "_97yLm",
	"article": "SXbNQ",
	"opinion": "yPU5s",
	"podcast": "iikwm",
	"title": "TojBu",
	"slide": "tGRQ5",
	"buttonBox": "d8hsz",
	"loader": "qvUOc"
};
module.exports = ___CSS_LOADER_EXPORT___;
