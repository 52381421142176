var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition-group',{ref:"rootEl",class:( _obj = {}, _obj[_vm.css.root] = true, _obj[_vm.css[_vm.sliderOptions.type]] = true, _obj[_vm.css[("rows-" + (_vm.sliderOptions.itemRows))]] = true, _obj[_vm.css.withItemsShadows] = _vm.sliderOptions.withItemsShadows, _obj ),attrs:{"name":"fade","tag":"div"}},[(_vm.data.length)?_c('div',{key:"content"},[_c('BaseSlider',{class:_vm.css.slider,attrs:{"buttons-align":_vm.sliderOptions.buttonsAlign,"buttons-justify":_vm.sliderOptions.buttonsJustify,"buttons-size":_vm.sliderOptions.buttonsSize,"item-gap":_vm.sliderOptions.itemGap,"item-rows":_vm.sliderOptions.itemRows,"item-width":_vm.sliderOptions.itemWidth,"items":_vm.slides,"scroll-snap-align":_vm.sliderOptions.scrollSnapAlign,"title":_vm.sliderOptions.title,"with-dots":_vm.sliderOptions.withDots,"with-items-shadows":_vm.sliderOptions.withItemsShadows},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var item = ref.item;
return [(_vm.sliderOptions.itemRows > 1)?_vm._l((item),function(row,rowKey){return _c('div',{key:rowKey},[(_vm.sliderOptions.type && _vm.slidesComponents[_vm.sliderOptions.type])?_c(_vm.slidesComponents[_vm.sliderOptions.type],{tag:"component",class:_vm.css.slide,style:({
              width: _vm.sliderOptions.itemWidth,
            }),attrs:{"data":row.data,"options":_vm.slidesOptions[_vm.sliderOptions.type] || {},"size":{ height: 1, width: 1 }}}):_vm._e()],1)}):(_vm.sliderOptions.type && _vm.slidesComponents[_vm.sliderOptions.type])?[(_vm.sliderOptions.type === 'podcast')?_c(_vm.slidesComponents[_vm.sliderOptions.type],{tag:"component",class:_vm.css.slide,style:({
            width: _vm.sliderOptions.itemWidth,
          }),attrs:{"item":item.data}}):_c(_vm.slidesComponents[_vm.sliderOptions.type],{tag:"component",class:_vm.css.slide,style:({
            width: _vm.sliderOptions.itemWidth,
          }),attrs:{"data":item.data,"options":_vm.slidesOptions[_vm.sliderOptions.type] || {},"size":{ height: 1, width: 1 }}})]:_vm._e()]}}],null,false,3836838896)}),_vm._v(" "),(_vm.sliderOptions.moreTo)?_c('div',{class:_vm.css.buttonBox},[_c('BaseButton',{class:_vm.css.button,attrs:{"theme":"yellow","to":_vm.sliderOptions.moreTo}},[_vm._v("\n        Все материалы\n      ")])],1):_vm._e()],1):_c('div',{key:"loader"},[_c('MicroLoader',{class:_vm.css.loader})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }