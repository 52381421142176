import { computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref, useContext, useCssModule, watch, } from '@nuxtjs/composition-api';
import { ICON_BUTTON_SIZE_MAP } from '~/components/IconButton';
export default defineComponent({
    name: 'BaseSlider',
    props: {
        buttonsAlign: {
            default: 'center',
            type: String,
        },
        buttonsJustify: {
            default: 'space-between',
            type: String,
        },
        buttonsSize: {
            default: 'big',
            type: String,
        },
        edgeGap: {
            default: () => ({
                mobile: '8px',
                mobileMedium: '8px',
                mobileWide: '16px',
                tablet: 0,
                desktop: 0,
                desktopMedium: 0,
                desktopWide: 0,
            }),
            type: [String, Object],
        },
        items: {
            required: true,
            type: Array,
        },
        itemGap: {
            default: '16px',
            type: [String, Object],
        },
        itemRows: {
            default: 1,
            type: Number,
        },
        itemWidth: {
            default: '',
            type: [String, Object],
        },
        isButtonsHoverable: {
            default: false,
            type: Boolean,
        },
        scrollSnapAlign: {
            default: 'start',
            type: String,
            validator(value) {
                return ['center', 'end', 'start', 'start end'].includes(value);
            },
        },
        scrollSnapStop: {
            default: 'normal',
            type: String,
            validator(value) {
                return ['always', 'normal'].includes(value);
            },
        },
        title: {
            default: '',
            type: String,
        },
        titleSize: {
            default: 'small',
            type: String,
            validator(value) {
                return ['small', 'medium', 'large'].includes(value);
            },
        },
        subtitle: {
            default: '',
            type: String,
        },
        withDots: {
            default: true,
            type: Boolean,
        },
        withItemsShadows: {
            default: false,
            type: Boolean,
        },
        withSliderBorder: {
            default: false,
            type: Boolean,
        },
    },
    setup(props) {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        /**
         * Context.
         */
        const context = useContext();
        /**
         * Viewport.
         */
        const viewport = context.$viewport;
        const intersectionObserver = ref();
        const itemsEl = ref();
        const sliderEl = ref();
        const currentIndex = ref(1);
        const withButtons = ref(false);
        /**
         * Scroll state.
         */
        const scroll = reactive({
            current: -1,
            width: 0,
        });
        const currentEdgeGap = computed(() => {
            if (props.edgeGap && props.edgeGap[viewport.breakpoint]) {
                return props.edgeGap[viewport.breakpoint];
            }
            return props.edgeGap;
        });
        const currentGap = computed(() => {
            if (props.itemGap && props.itemGap[viewport.breakpoint]) {
                return props.itemGap[viewport.breakpoint];
            }
            return props.itemGap;
        });
        const currentWidth = computed(() => {
            if (props.itemWidth && typeof props.itemWidth === 'string') {
                return props.itemWidth;
            }
            if (props.itemWidth && props.itemWidth[viewport.breakpoint]) {
                return props.itemWidth[viewport.breakpoint];
            }
            if (viewport.isLessThan('tablet')) {
                return `calc(100% - ${parseInt(currentGap.value, 10) * 2}px)`;
            }
            if (viewport.isLessThan('desktopWide')) {
                return `calc(50% - ${parseInt(currentGap.value, 10) / 2}px)`;
            }
            return `calc(33% - ${parseInt(currentGap.value, 10) / 2}px)`;
        });
        const canScrollBackward = computed(() => scroll.current > 0);
        const canScrollForward = computed(() => scroll.current < scroll.width - 32);
        /**
         * Handles "scroll" event.
         */
        function handleScroll() {
            scroll.current = itemsEl.value?.scrollLeft || 0;
        }
        /**
         * Scroll's to a previous item.
         */
        function scrollBackward() {
            if (!canScrollBackward || !itemsEl.value) {
                return;
            }
            const { children } = itemsEl.value;
            let i = children.length;
            while (i--) {
                const child = children[i - 1];
                if (!child) {
                    continue;
                }
                if (scroll.current < child.offsetLeft + child.offsetWidth - 16) {
                    continue;
                }
                const left = child.offsetLeft;
                return itemsEl.value.scrollTo({ behavior: 'smooth', left });
            }
        }
        /**
         * Scroll's to a next item.
         */
        function scrollForward() {
            if (!canScrollForward || !itemsEl.value) {
                return;
            }
            const { children } = itemsEl.value;
            for (let i = 0, l = children.length; i < l; i++) {
                const child = children[i];
                if (scroll.current > child.offsetWidth + child.offsetLeft - 16) {
                    continue;
                }
                const nextChild = children[i + 1];
                if (!nextChild) {
                    continue;
                }
                let left = nextChild.offsetLeft;
                if (scroll.width - scroll.current < nextChild.offsetWidth) {
                    left = scroll.width;
                }
                return itemsEl.value.scrollTo({ behavior: 'smooth', left });
            }
        }
        onBeforeUnmount(() => {
            intersectionObserver.value?.disconnect();
            itemsEl.value?.removeEventListener('scroll', handleScroll);
        });
        onMounted(() => {
            intersectionObserver.value = new IntersectionObserver(([entry]) => {
                if (entry.isIntersecting) {
                    const target = entry.target;
                    const { index = '0' } = target.dataset;
                    currentIndex.value = parseInt(index, 10);
                }
            }, {
                threshold: 0.5,
            });
            watch([() => viewport.breakpoint, () => props.items], async ([breakpoint]) => {
                await new Promise((resolve) => setTimeout(resolve, 500));
                if (!itemsEl.value || !sliderEl.value) {
                    return;
                }
                itemsEl.value.scrollTo({ left: 0 });
                if (breakpoint.includes('mobile')) {
                    for (const child of Array.from(itemsEl.value.children)) {
                        intersectionObserver.value?.observe(child);
                    }
                    itemsEl.value.removeEventListener('scroll', handleScroll);
                    scroll.current = -1;
                    scroll.width = 0;
                    return;
                }
                intersectionObserver.value?.disconnect();
                itemsEl.value.addEventListener('scroll', handleScroll);
                const { scrollWidth } = itemsEl.value;
                const { offsetWidth } = sliderEl.value;
                scroll.current = 0;
                scroll.width = scrollWidth - offsetWidth;
                withButtons.value = scrollWidth > offsetWidth;
            }, {
                immediate: true,
            });
        });
        return {
            ICON_BUTTON_SIZE_MAP,
            canScrollForward,
            canScrollBackward,
            css,
            currentEdgeGap,
            currentGap,
            currentIndex,
            currentWidth,
            itemsEl,
            scroll,
            scrollBackward,
            scrollForward,
            sliderEl,
            withButtons,
        };
    },
});
